import "./styles.css";
import Spline1 from "./hero";
import 'bootstrap/dist/css/bootstrap.min.css';



export default function App() {
  return (
    <div>
      <Spline1 />

    </div>
  );
}
