import React from 'react';

export default function ContactMe() {
  return (
    <div>
      <h1>Contact Me</h1>
      <p>This is the Contact Me page.</p>
    </div>
  );
}
