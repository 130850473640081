import React from 'react';

export default function MyWork() {
  return (
    <div>
      <h1>My Work</h1>
      <p>This is the My Work page.</p>
    </div>
  );
}
